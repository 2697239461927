import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React from 'react';
import { toast } from 'react-toastify';

const AddSiteDialog = ({ open, setOpen, setAddedSite }) => {
  const [url, setUrl] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState('');

  const addSite = async () => {
    setLoading(true);
    setErr('');

    try {
      let fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const res = await fn({
        command: 'addSite',
        url,
        userName: username,
        password
      });

      setAddedSite((v) => [...v, res.data.site]);
      setOpen(false);
    } catch (e) {
      if (e.code === 'functions/invalid-argument') {
        setErr('Please enter valid information');
      } else if (e.code === 'functions/permission-denied') {
        setErr("Invalid username or password. Ensure your credentials are correct and the user have post edit permissions. The URL must match exactly as seen in the browser.")
      }
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Add Website</DialogTitle>

      <DialogContent>
        <Alert severity="info">
          If you have cloudflare or other security measures enabled for a site, please first
          whitelist our IP address
          <strong
            style={{
              cursor: 'pointer'
            }}
            onClick={() => {
              navigator.clipboard.writeText('35.193.8.109');
              toast.success('IP Copied');
            }}
          >
            {' '}
            35.193.8.109
          </strong>
        </Alert>

        <br></br>
        <Stack>
          <TextField
            label="Site URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://example.com"
          />
          <br></br>
          <b style={{ marginBottom: '5px' }}>
            For multi-author setups, ensure using an admin account for comprehensive management.
          </b>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder=""
          />
          <br></br>

          <b style={{ marginBottom: '5px' }}>
            An application-specific password enhances security without compromising main access.
          </b>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Stack>

        {err && (
          <Alert style={{ marginTop: '15px' }} severity="error">
            {err}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <LoadingButton variant="contained" onClick={addSite} loading={loading}>
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddSiteDialog;
