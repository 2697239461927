import { useState } from "react";
import { Box, TextField, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRef } from "react";
import { H3 } from "app/components/Typography";
import { LoadingButton } from "@mui/lab";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function UpdateOpenAIkeyDialog({
  keys,
  setKeys,
  op,
  setOp,
  seletedCred,
  setSeletedCred,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [values, setValues] = useState({ name: "", key: "" });
  const formRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (op >= 0 && keys) {
      setValues(keys[op]);
      setError(null);
    }
  }, [op, keys]);

  const handleSave = async () => {
    setIsSaving(true);
    setError(null);

    try {
      const fn = httpsCallable(getFunctions(), "manageUserData");
      let res = await fn({
        command: "updateUserOpenAIKey",
        newKey: values.key,
        newName: values.name,
        dataIndex: op,
      });

      if (res.data.status === "error") {
        setError(res.data.message);
      } else {
        let newKeys = [...keys];
        let oldKey = keys[op];
        newKeys[op] = values;
        setKeys(newKeys);

        if (seletedCred === oldKey.key) {
          setSeletedCred(values.key);
        }

        toast.success("OpenAI key updated successfully");
        setOp(-1);
      }
    } catch (e) {
      console.log(e);
      toast.error("Error updating OpenAI key");
    }

    setIsSaving(false);
  };

  const handleCancel = () => {
    setOp(-1);
  };

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={op >= 0}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Upddate OpenAI API key
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <form ref={formRef}>
              <H3 style={{ minWidth: "500px" }}>Key Name</H3>

              <center>
                <TextField
                  fullWidth
                  autoFocus
                  required
                  variant="outlined"
                  placeholder="Name of the Key"
                  value={values.name}
                  onChange={(e) =>
                    setValues({ ...values, name: e.target.value })
                  }
                ></TextField>
              </center>
              <br></br>
              <center>
                <TextField
                  fullWidth
                  autoFocus
                  variant="outlined"
                  label="API Key"
                  placeholder="sk-xxxxxxxxxxxxxxxxxxxxxxxx"
                  value={values.key}
                  onChange={(e) =>
                    setValues({ ...values, key: e.target.value })
                  }
                ></TextField>
              </center>
            </form>

            {error && <p style={{ color: "red" }}>{error}</p>}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} variant="outlined">
            Cancel
          </Button>

          <LoadingButton
            loading={isSaving}
            onClick={handleSave}
            color="primary"
            variant="contained"
            autoFocus
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
