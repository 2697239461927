import { useState } from "react";
import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRef } from "react";
import { H3 } from "app/components/Typography";
import { LoadingButton } from "@mui/lab";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function UpdateCredDialog({ setCreds, op, setOp }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [values, setValues] = useState({
    name: "",
    channel_id: "",
    server_id: "",
    dc_token: "",
  });
  const formRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (op === -1) return;
    setError(null);
    setIsFetching(true);


    const fetchDiscordCredential = async () => {
      try {
        let fn = httpsCallable(getFunctions(), "manageUserData");
        const res = await fn({ command: "getUserCredential", dataIndex: op });

        setValues(res.data.cred);
        setIsFetching(false);
      } catch (e) {
        console.log(e);
        toast.error("Error fetching Discord Credential");
      }
    };

    fetchDiscordCredential();
  }, [op]);

  const handleSave = async () => {
    if (!formRef.current.reportValidity()) return;

    setIsSaving(true);
    setError(null);
    try {
      let fn = httpsCallable(getFunctions(), "manageUserData");
      const res = await fn({
        command: "updateUserCredential",
        cred: values,
        dataIndex: op,
      });
     
      if (res.data.status === "error") {
        setError(res.data.message);
      } else {
        setCreds((prev) => {
          let newCreds = [...prev];
          newCreds[op] = values;
          return newCreds;
        });
        setOp(-1);
      }
    } catch (e) {
      console.log(e);
      toast.error("Error updating Discord Credential");
    }

    setIsSaving(false);
  };

  const handleCancel = () => {
    setOp(-1);
  };

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={op > -1}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        {isFetching ? (
          <>
            <DialogTitle id="responsive-dialog-title">
              Fetching Credential
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <center>
                  <CircularProgress size={50} />
                </center>
              </DialogContentText>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle id="responsive-dialog-title">
              Update Credential
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <form ref={formRef}>
                  <H3 style={{ minWidth: "500px" }}>
                    Credential Name
                  </H3>

                  <center>
                    <TextField
                      fullWidth
                      autoFocus
                      required
                      variant="outlined"
                      placeholder="Name of the Credential"
                      value={values.name}
                      onChange={(e) =>
                        setValues({ ...values, name: e.target.value })
                      }
                    ></TextField>
                  </center>

                  <br></br>
                  <H3>Credential</H3>

                  <center>
                    <TextField
                      fullWidth
                      autoFocus
                      required
                      variant="outlined"
                      placeholder="Server ID"
                      label="Server ID"
                      style={{ marginTop: "10px" }}
                      value={values.server_id}
                      onChange={(e) =>
                        setValues({ ...values, server_id: e.target.value })
                      }
                    ></TextField>
                  </center>

                  <br></br>
                  <center>
                    <TextField
                      fullWidth
                      autoFocus
                      required
                      variant="outlined"
                      placeholder="Channel ID"
                      label="Channel ID"
                      value={values.channel_id}
                      onChange={(e) =>
                        setValues({ ...values, channel_id: e.target.value })
                      }
                    ></TextField>
                  </center>

                  <br></br>
                  <center>
                    <TextField
                      fullWidth
                      autoFocus
                      required
                      variant="outlined"
                      placeholder="Discord Token"
                      value={values.dc_token}
                      label="Discord Token"
                      onChange={(e) =>
                        setValues({ ...values, dc_token: e.target.value })
                      }
                    ></TextField>
                  </center>
                </form>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} variant="outlined">
                Cancel
              </Button>

              <LoadingButton
                loading={isSaving}
                onClick={handleSave}
                color="primary"
                variant="contained"
                autoFocus
              >
                Save
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
}
