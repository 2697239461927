import { useState } from 'react';
import { Box, TextField, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRef } from 'react';
import { H3 } from 'app/components/Typography';
import { LoadingButton } from '@mui/lab';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';


export default function AddOpenAIkeyDialog({ setData, op }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [values, setValues] = useState({ name: '', key: '' });
  const formRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const handleSave = async () => {
    setIsSaving(true);
    setError(null);

    try {
      const fn = httpsCallable(getFunctions(), 'manageUserData');
      let res = await fn({
        command: 'addUserOpenAIKey',
        ...values
      });

      if (res.data.status === 'error') {
        setError(res.data.message);
      } else {
        toast.success('OpenAI key added successfully');
        setData(null);
      }
    } catch (e) {
      console.log(e);
      toast.error('Error adding OpenAI key');
    }

    setIsSaving(false);
  };

  const handleCancel = () => {
    setData(null);
  };

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={op}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Add OpenAI API key</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <form ref={formRef}>
              <H3 style={{ minWidth: '500px' }}>Give it a name to recognize</H3>

              <center>
                <TextField
                  fullWidth
                  autoFocus
                  required
                  variant="outlined"
                  placeholder="Name of the Key"
                  value={values.name}
                  onChange={(e) => setValues({ ...values, name: e.target.value })}
                ></TextField>
              </center>
              <br></br>
              <center>
                <TextField
                  fullWidth
                  autoFocus
                  variant="outlined"
                  label="API Key"
                  placeholder="sk-xxxxxxxxxxxxxxxxxxxxxxxx"
                  value={values.key}
                  onChange={(e) => setValues({ ...values, key: e.target.value })}
                ></TextField>
              </center>
            </form>

            {error && <p style={{ color: 'red' }}>{error}</p>}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} variant="outlined">
            Cancel
          </Button>

          <LoadingButton
            loading={isSaving}
            onClick={handleSave}
            color="primary"
            variant="contained"
            autoFocus
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
