import React from "react";

import {
  Box,
  Button,
  Checkbox,
  Grid,
  Icon,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import VerifyEmail from "../VerifyEmail";
import useAuth from "app/hooks/useAuth";
import { useEffect, useState } from "react";
import { H2, H3 } from "app/components/Typography";
import Loading from "app/components/MatxLoading";
import { doc, getFirestore, onSnapshot, updateDoc } from "firebase/firestore";

import UpgradeDialog from "../UpgradePlanDialog";
import AddCredDialog from "./AddCredDialog";
import { getFunctions, httpsCallable } from "firebase/functions";
import AddOpenAIkeyDialog from "./AddOpenAIkeyDialog";
import { toast } from "react-toastify";
import UpdateOpenAIkeyDialog from "./UpdateOpenAIkeyDialog";
import UpdateCredDialog from "./updateCredentialDialog";

export const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "center",
  position: "relative",
}));

export const CardDiv = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  padding: "20px",
  boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
  borderRadius: "5px",
  flexDirection: "column",
  width: "100%",
}));

const CredentialArea = (props) => {
  const { user } = useAuth();
  const [creds, setCreds] = useState([]);
  const [selectedCred, setSelectedCred] = useState(null);
  const [opAddCred, setOpAddCred] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showChooseCred, setShowChooseCred] = useState(true);
  const [opUpdateCred, setOpUpdateCred] = useState(-1);

  useEffect(() => {
    if (window.localStorage.getItem("showChooseCred") == "false")
      setShowChooseCred(false);
  }, []);

  useEffect(() => {
    if (props.userData) {
      setCreds(props.userData.dc_creds);
      setSelectedCred(props.userData.selected_dc_cred);
    }
  }, [props.userData]);

  const setSelected = async (index) => {
    setIsSaving(true);

    await updateDoc(doc(getFirestore(), "userData", user.uid), {
      selected_dc_cred: index,
    });
    setIsSaving(false);
    setSelectedCred(index);
  };

  const deleteCredential = async (index) => {
    setIsSaving(true);

    let sl = selectedCred;

    if (creds.length == 1) sl = -1;
    else if (sl >= creds.length - 1) {
      sl = creds.length - 2;
    }

    const fn = httpsCallable(getFunctions(), "manageUserData");
    await fn({
      command: "removeUserCredential",
      toUpdate: {
        itemToRemove: creds[index],
        selected_dc_cred: sl,
      },
    });

    setIsSaving(false);
  };

  return (
    <div>
      <AddCredDialog
        op={opAddCred}
        setData={(data) => {
          if (data) {
            setCreds([...creds, data]);
          }
          setOpAddCred(false);
        }}
      />

      <UpdateCredDialog
        setCreds={setCreds}
        op={opUpdateCred}
        setOp={setOpUpdateCred}
      />
      <Grid container columnSpacing={2} rowSpacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <CardDiv height={"100%"}>
            <Stack>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Stack minWidth={"70%"}>
                  <H3>Discord Credentials</H3>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    width={"95%"}
                  >
                    This is used for generating images using midjourney discord
                    bot
                  </Typography>
                </Stack>
                <Button
                  style={{
                    marginTop: "-4px",
                  }}
                  disabled={selectedCred === null}
                  color="primary"
                  startIcon={<Icon>add</Icon>}
                  onClick={() => {
                    if (user.plan.saas.dc_token_limit != 0) {
                      if (creds.length >= user.plan.saas.dc_token_limit) {
                        props.setPlanMsg(
                          "You have reached the limit of custom credentials for your plan"
                        );
                        return;
                      } else setOpAddCred(true);
                    } else {
                      props.setPlanMsg(
                        "You need to upgrade your plan to add custom credentials"
                      );
                    }
                  }}
                >
                  Add
                </Button>
              </div>
              <br></br>
              {selectedCred !== null && (
                <div>
                  <Box>
                    <Radio
                      disabled={isSaving}
                      checked={selectedCred == -1}
                      onChange={(e) => {
                        setSelected(-1);
                      }}
                    />
                    Use AIphonse Discord Credential
                  </Box>

                  {creds.map((cred, index) => (
                    <Box key={cred.dc_token + cred.channel_id}>
                      <Radio
                        disabled={isSaving}
                        checked={selectedCred == index}
                        onChange={(e) => {
                          setSelected(index);
                        }}
                      />

                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: "10px",
                          minWidth: "60%",
                        }}
                      >
                        <Typography variant="body1">{cred.name}</Typography>
                      </div>

                      <Button
                        disabled={isSaving}
                        onClick={() => setOpUpdateCred(index)}
                      >
                        <Icon>edit</Icon>
                      </Button>

                      <Button
                        disabled={isSaving}
                        onClick={() => deleteCredential(index)}
                      >
                        <Icon>delete</Icon>
                      </Button>
                    </Box>
                  ))}
                </div>
              )}
              <Stack
                spacing={2}
                marginTop="24px"
                direction="row"
                alignItems="center"
              >
                <Checkbox
                  inputProps={{
                    "aria-label":
                      "Show Credential choosing dialog each time before generating image",
                  }}
                  checked={showChooseCred}
                  onChange={(e) => {
                    setShowChooseCred(e.target.checked);
                    window.localStorage.setItem(
                      "showChooseCred",
                      e.target.checked ? "true" : "false"
                    );
                  }}
                />
                Show Credential choosing dialog each time before generating
                image.
              </Stack>
              {selectedCred === null && <Loading />}
            </Stack>
          </CardDiv>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardDiv height={"100%"}>
            <OpenAIArea
              userData={props.userData}
              setPlanMsg={props.setPlanMsg}
            />
          </CardDiv>
        </Grid>
      </Grid>

      <br></br>
      <Typography variant="caption" color="textSecondary">
        * The selected credentials here will be used for genrating images
        through Smart Generator, Prompt Generator or for any wordpress sites
        that does not have any credentials set.
      </Typography>
    </div>
  );
};

const OpenAIArea = (props) => {
  const { user } = useAuth();
  const [openAiKeys, setOpenAIKeys] = useState([]);
  const [selectedCred, setSelectedCred] = useState(null);
  const [opAddCred, setOpAddCred] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openKeyForUpdate, setOpenKeyForUpdate] = useState(-1);

  useEffect(() => {
    if (props?.userData) {
      setOpenAIKeys(props.userData?.openai_keys || []);
      setSelectedCred(props.userData.openai_key);
    }
  }, [props.userData]);

  const setSelected = async (key) => {
    setIsSaving(true);

    await updateDoc(doc(getFirestore(), "userData", user.uid), {
      openai_key: key,
    });
    setIsSaving(false);
    setSelectedCred(key);
  };

  const deleteCredential = async (cred) => {
    if (cred.key === selectedCred) {
      toast.error("You can not delete the selected credential");
      return;
    }

    setIsSaving(true);

    try {
      const fn = httpsCallable(getFunctions(), "manageUserData");
      await fn({
        command: "removeUserOpenAIKey",
        toUpdate: {
          itemToRemove: cred,
        },
      });
    } catch (e) {
      console.log(e);
      toast.error("Error deleting the credential");
    }

    setIsSaving(false);
  };

  return (
    <Stack>
      <AddOpenAIkeyDialog
        setData={(d) => {
          if (!d) setOpAddCred(false);
          else {
            setOpenAIKeys([...openAiKeys, d]);
            setOpAddCred(false);
          }
        }}
        op={opAddCred}
      />
      <UpdateOpenAIkeyDialog
        keys={openAiKeys}
        setKeys={setOpenAIKeys}
        op={openKeyForUpdate}
        setOp={setOpenKeyForUpdate}
        seletedCred={selectedCred}
        setSeletedCred={setSelectedCred}
      />
      <div
        style={{
          display: "flex",
        }}
      >
        <Stack minWidth={"70%"}>
          <H3>OpenAI Credentials</H3>
          <Typography variant="caption" color="textSecondary" width={"95%"}>
            This is used for fine tuning the image generation idea for your
            needs.
          </Typography>
        </Stack>
        <Button
          style={{
            marginTop: "-4px",
          }}
          disabled={selectedCred === null}
          color="primary"
          startIcon={<Icon>add</Icon>}
          onClick={() => {
            if (user.plan.saas.openai_key_limit != 0) {
              if (openAiKeys.length >= user.plan.saas.openai_key_limit) {
                props.setPlanMsg(
                  "You have reached the limit of custom credentials for your plan"
                );
                return;
              } else setOpAddCred(true);
            } else {
              props.setPlanMsg(
                "You need to upgrade your plan to add custom credentials"
              );
            }
          }}
        >
          Add
        </Button>
      </div>
      <br></br>
      {selectedCred !== null && (
        <div>
          <RadioGroup
            value={selectedCred}
            onChange={(e) => {
              setSelected(e.target.value);
            }}
          >
            {openAiKeys.map((cred, index) => (
              <Box
                key={cred.key}
                display="flex"
                alignItems="center"
                margin="5px 0"
              >
                <Radio disabled={isSaving} value={cred.key} />

                <div
                  style={{
                    display: "inline-block",
                    marginLeft: "10px",
                    minWidth: "60%",
                  }}
                >
                  <Typography variant="body1">{cred.name}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {cred.key.slice(0, 3) +
                      "*******************" +
                      cred.key.slice(-3)}
                  </Typography>
                </div>

                <Button
                  disabled={isSaving}
                  onClick={() => setOpenKeyForUpdate(index)}
                >
                  <Icon>edit</Icon>
                </Button>

                <Button
                  disabled={isSaving}
                  onClick={() => deleteCredential(cred)}
                >
                  <Icon>delete</Icon>
                </Button>
              </Box>
            ))}
          </RadioGroup>
        </div>
      )}
      {selectedCred === null && <Loading />}
    </Stack>
  );
};

export default Credential = () => {
  const { user } = useAuth();
  const [planMsg, setPlanMsg] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(getFirestore(), "userData", user.uid),
      (doc) => {
        setUserData(doc.data());
      }
    );

    return unsubscribe;
  }, [user]);

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  return (
    <div>
      <UpgradeDialog msg={planMsg} setMsg={setPlanMsg} />

      <FlexBox style={{ marginTop: "25px", marginBottom: "35px" }}>
        <H2 style={{ position: "absolute", top: "-15px", left: "12px" }}>
          Credentials
        </H2>
      </FlexBox>

      <FlexBox>
        <Box m={{ xs: 2, sm: 4, md: 5 }} width={{ sm: "70%" }}>
          <CredentialArea setPlanMsg={setPlanMsg} userData={userData} />

          <>
            <Grid item xs={12} md={5} sx={{ marginTop: "50px" }}>
              <H3>Need Help?</H3>
              <br></br>
              <div>
                <ul>
                  <li>
                    <Link
                      href={
                        "https://www.androidauthority.com/get-discord-token-3149920/"
                      }
                      target="_blank"
                    >
                      How to get your discord token / salai token
                    </Link>
                  </li>

                  <p>
                    To get the <var>SERVER_ID</var> and <var>CHANNEL_ID</var>
                  </p>
                  <li>
                    <Link
                      href="https://discord.com/blog/starting-your-first-discord-server"
                      target="_blank"
                    >
                      create a discord server
                    </Link>{" "}
                    and{" "}
                    <Link
                      href="https://docs.midjourney.com/docs/invite-the-bot"
                      target="_blank"
                    >
                      invite the midjourney bot
                    </Link>
                  </li>
                  <p>
                    when you click on a channel in your server in the browser
                    expect to have the follow URL pattern
                    <br></br>
                    <br></br>
                    <p style={{ lineBreak: "anywhere" }}>
                      https://discord.com/channels/<var>SERVER_ID</var>/
                      <var>CHANNEL_ID</var>
                    </p>
                  </p>
                </ul>
              </div>
            </Grid>
          </>
        </Box>
      </FlexBox>
    </div>
  );
};
